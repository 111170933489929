import React, { useEffect } from "react";
// import "./printInvoice.scss";
import Barcode from "react-barcode";

const PrintBarcode = ({ recordSelected = null }) => {
  const numberOfBarcodes = [...Array(parseInt(recordSelected.count)).keys()];
  return (
    <div id="printBarcode">
      {numberOfBarcodes?.length > 0 &&
        numberOfBarcodes.map((val) => {
          const isVariatOne = recordSelected?.product_variant1_name !== "null";
          const isVariatTwo = recordSelected?.product_variant2_name !== "null";
          let price =
            recordSelected?.prices?.product_sale_price_incl ||
            recordSelected?.product_sale_price;
            price = `${price}`.slice(0, 7);
            const productSku = recordSelected?.product_sku.slice(0, 16);
          return (
            <div className="printBarcode-file" key={val}>
              <div>
                <div className="center">
                  {recordSelected?.storeObj?.store_name}
                </div>
              </div>
              <div className="w-100">
                <div className="topleft text-elipse">{recordSelected?.product_name}</div>
                <div className="topright">Rs: {price}</div>
              </div>
              <Barcode
                value={productSku}
                format="CODE128" 
                width={1.5}
                height={75}
                fontSize={10}
              />
              <div className="w-100">
                {isVariatOne && (
                  <div className="topleft text-elipse">
                    {recordSelected?.product_variant1_value}
                  </div>
                )}
                {isVariatTwo && (
                  <div className="topright">
                    {recordSelected?.product_variant2_value}
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default PrintBarcode;
