
import React, { useState, useEffect } from "react";
import { Form, Upload, Button, message } from "antd";
import { useHistory } from "react-router-dom";
import { UploadOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import * as EcommerceApiUtil from '../../../../../utils/api/ecommerce-api-utils';
import * as Helpers from "../../../../../utils/helpers/scripts";



var bulkProcess = {
    process: 'Started',
    totalCount: 0,
    doneCount: 0,
    chunk: 50,
};



const OmniBulkOrders = () => {
    const history = useHistory();
    const [fileList, setFileList] = useState([]);

    let mounted = true;


    useEffect(() => {

        return () => {
            mounted = false;
        }

    }, []);  //imp to render when history prop changes


    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleUpload = async (values) => {
        //console.log("Success:", values);
        console.log(fileList[0]); //imp
        var file = fileList[0];

        if (file && fileExtention(file.name) == "csv") {
            var reader = new FileReader();
            reader.readAsText(file);
            reader.onload = function (evt) {
                // code to convert file data and render in json format
                //var json = csvJSON(evt.target.result); //2nd custom function for conversion
                //console.log(json);
                //console.log(Helpers.CSV2JSONUpdated(evt.target.result));
                let jsonOutput = JSON.parse(Helpers.CSV2JSON(evt.target.result));  //1st custom function for conversion
                //console.log(jsonOutput);
                let references = jsonOutput.map((e) => e.orders_id);
                console.log(references);

                /*-------------------------------*/

                bulkProcess.totalCount = references.length;
                bulkProcess.queue = references;
                document.getElementById('app-loader-container').style.display = "block";
                let bulkUploadCheck = "process_orders";
                //uploadChunk(bulkProcess.queue.splice(0, bulkProcess.chunk), bulkUploadCheck);     //imp prev ver
                uploadChunk(references, bulkUploadCheck);


            };
            reader.onerror = function (evt) {
                message.error("error reading file");
            };
        } else {
            message.error("Not a csv file");
        }
    };



    const handleCancelOmniOrders = async (values) => {
        //console.log("cancel-orders-click:");
        console.log(fileList[0]); //imp
        var file = fileList[0];

        if (file && fileExtention(file.name) == "csv") {
            var reader = new FileReader();
            reader.readAsText(file);
            reader.onload = function (evt) {
                // code to convert file data and render in json format
                //var json = csvJSON(evt.target.result); //2nd custom function for conversion
                //console.log(json);
                //console.log(Helpers.CSV2JSONUpdated(evt.target.result));
                let jsonOutput = JSON.parse(Helpers.CSV2JSON(evt.target.result));  //1st custom function for conversion
                //console.log(jsonOutput);
                let references = jsonOutput.map((e) => e.orders_id);
                console.log(references);
                //console.log("imp-after", jsonOutput);  //imp after reatin original

                /*-------------------------------*/

                bulkProcess.totalCount = references.length;
                bulkProcess.queue = references;
                document.getElementById('app-loader-container').style.display = "block";
                let bulkUploadCheck = "cancel_orders";
                //uploadChunk(bulkProcess.queue.splice(0, bulkProcess.chunk), bulkUploadCheck);     //imp prev ver
                uploadChunk(references, bulkUploadCheck);

            };
            reader.onerror = function (evt) {
                message.error("error reading file");
            };
        } else {
            message.error("Not a csv file");
        }
    };



    async function uploadChunk(references, bulkUploadCheck) {
        //console.log(products);
        let getOmniInvoicesResponse;
        if (bulkUploadCheck === "process_orders") {
            getOmniInvoicesResponse = await EcommerceApiUtil.getomniBulkOrdersId(
                references
            );
        }
        if (bulkUploadCheck === "cancel_orders") {
            getOmniInvoicesResponse = await EcommerceApiUtil.getomniBulkOrdersInnerIds(
                references
            );
        }

        console.log("productsBulkUploadResponse:", getOmniInvoicesResponse);

        if (getOmniInvoicesResponse.hasError) {
            console.log(
                "Cant get Omni Orders -> ",
                getOmniInvoicesResponse.errorMessage
            );

            document.getElementById('app-loader-container').style.display = "none";
            message.error(getOmniInvoicesResponse.errorMessage, 2);

        } else {

            if (mounted) {       //imp if unmounted

                //document.getElementById('app-loader-container').style.display = "none";
                let inv;
                //message.success(getOmniInvoicesResponse.message, 2);

                if (bulkUploadCheck === "process_orders") {
                    inv = getOmniInvoicesResponse.invoices.map((e) => e.id);
                    confirmOeSalesOrders(inv);
                }
                if (bulkUploadCheck === "cancel_orders") {
                    inv = getOmniInvoicesResponse.data.map((e) => e.invoice_id);
                    //console.log(inv);
                    cancelOeSalesOrders(inv);

                }

            }

        }

    }



    const confirmOeSalesOrders = async (selectedOeSalesOrders) => {
        //console.log(selectedOeSalesOrders);
        //document.getElementById('app-loader-container').style.display = "block";
        const confirmOeSalesOrdersResponse = await EcommerceApiUtil.confirmOeSalesOrders(selectedOeSalesOrders);
        console.log('confirmOeSalesOrdersResponse:', confirmOeSalesOrdersResponse);

        if (confirmOeSalesOrdersResponse.hasError) {
            console.log('Cant Confirm Oe Sales Orders Data -> ', confirmOeSalesOrdersResponse.errorMessage);
            document.getElementById('app-loader-container').style.display = "none";
            message.warning(confirmOeSalesOrdersResponse.errorMessage, 3);
        }
        else {
            if (mounted) {     //imp if unmounted
                document.getElementById('app-loader-container').style.display = "none";   //imp but prev ver
                //message.success(confirmOeSalesOrdersResponse.message, 3);
                setTimeout(() => {
                    history.push({
                        pathname: '/ecommerce/orders',
                    });
                }, 500);

            }
        }
    }


    const cancelOeSalesOrders = async (invoices) => {
        //console.log(invoices);
        //document.getElementById('app-loader-container').style.display = "block";
        const cancelOeSalesOrdersResponse = await EcommerceApiUtil.cancelBulkOeSalesOrders(invoices);
        console.log('cancelOeSalesOrdersResponse:', cancelOeSalesOrdersResponse);

        if (cancelOeSalesOrdersResponse.hasError) {
            console.log('Cant Cancel Oe Sales Orders Data -> ', cancelOeSalesOrdersResponse.errorMessage);
            document.getElementById('app-loader-container').style.display = "none";
            message.error(cancelOeSalesOrdersResponse.errorMessage, 2);
        }
        else {
            console.log('res -> ', cancelOeSalesOrdersResponse);
            if (mounted) {     //imp if unmounted
                document.getElementById('app-loader-container').style.display = "none";
                //message.success(cancelOeSalesOrdersResponse.message, 2);
                setTimeout(() => {
                    history.push({
                        pathname: '/ecommerce/orders',
                    });
                }, 500);


            }
        }
    }



    function fileExtention(filename) {
        var parts = filename.split(".");
        return parts[parts.length - 1];
    }


    const imageUploadProps = {
        beforeUpload: (file) => {
            console.log(file);
            const isJpgOrPng =
                file.type === "image/jpeg" || file.type === "image/png";
            if (isJpgOrPng) {
                message.error("You cant upload JPG/PNG file!");
            } else {
                setFileList([file]);
            }

            return false;
        },
        fileList,
    };


    const onRemoveImage = (file) => {
        setFileList([]);
    };


    const handleCancel = () => {
        history.push({
            pathname: '/ecommerce/orders',
        });
    };




    return (
        <div className="page omni-bulk-sales-orders">
            <div className="page__header">
                <h1><Button type="primary" shape="circle" className="back-btn"
                    icon={<ArrowLeftOutlined />}
                    onClick={handleCancel} />Bulk File Upload</h1>
            </div>

            <div className="page__content">
                <div className="page__form">
                    <Form
                        name="basic"
                        layout="vertical"
                        //initialValues={{ }}
                        onFinish={handleUpload}
                        onFinishFailed={onFinishFailed}
                    >
                        <div className='form__row'>
                            <div className='form__col'>
                                <Form.Item  >
                                    <Upload {...imageUploadProps}
                                        listType="picture"
                                        onRemove={onRemoveImage}
                                    >
                                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                                    </Upload>
                                </Form.Item>

                            </div>

                            <div className="form__col">
                                <div >
                                    <Button type='primary'
                                        className='custom-btn--primary'
                                        htmlType="submit"
                                    >
                                        Process Orders
                                    </Button>

                                    <Button type='primary'
                                        className='cancel-omni-orders'
                                        onClick={() => handleCancelOmniOrders()}
                                    >
                                        Cancel Orders
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default OmniBulkOrders;
