
import React, { useEffect, useState } from "react";
import "../style.scss";
import { Tabs, Menu, Dropdown, Button, Input, Select, message, Pagination } from "antd";
import { useHistory } from "react-router-dom";
import OmniSalesOrders from "./omniSalesOrders";
import PrintSalesOrders from "./omniSalesOrders/printSalesOrder";
import { ProfileOutlined, DownOutlined } from "@ant-design/icons";
import * as EcommerceApiUtil from '../../../../utils/api/ecommerce-api-utils';
import * as SetupApiUtil from '../../../../utils/api/setup-api-utils';
import Constants from '../../../../utils/constants/constants';
import {
  getDataFromLocalStorage,
} from "../../../../utils/local-storage/local-store-utils";
import moment from 'moment';



const { TabPane } = Tabs;

let dataSearched = [];    //imp for searching




function EcommerceOrders() {
  const { Search } = Input;
  const { Option } = Select;
  const history = useHistory();
  const [paginationLimit, setPaginationLimit] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState("All Orders");
  const [omniSalesOrdersData, setOmniSalesOrdersData] = useState([]);
  const [selectedTabData, setSelectedTabData] = useState([]);
  const [userLocalStorageData, setUserLocalStorageData] = useState(null);
  const [selectedSaleOrdersRowKeys, setSelectedSaleOrdersRowKeys] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [loading, setLoading] = useState(true);
  const [outletData, setOutletData] = useState(null);
  const [selectedSalesInvoiceBulkDataArr, setSelectedSalesInvoiceBulkDataArr] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState(null);
  const [selectEntriesValue, setSelectEntriesValue] = useState(100);
  const [currentPageSearched, setCurrentPageSearched] = useState(1);
  const [showFetchOeOrdersCheck, setShowFetchOeOrdersCheck] = useState(false);




  let mounted = true;

  //const omniSalesOrdersEnum = { SALE: "3", COMPLETED: "4" }
  const omniSalesTabsEnum = {
    ALL: "All Orders", SALE: "Sale Orders", COMPLETED: "Completed Orders",
    RETURNED: "Returned Orders",
  }


  useEffect(() => {

    //fetchSalesOrdersData();                                             //imp prev ver
    const {filter} = handleSetOrdersStatus(currentTab);                   //imp new ver
    fetchSalesOrdersData(100, currentPage, filter);                       //imp new ver
  
    /*--------------set user local data-------------------------------*/
    var readFromLocalStorage = getDataFromLocalStorage(Constants.USER_DETAILS_KEY);
    readFromLocalStorage = readFromLocalStorage.data ? readFromLocalStorage.data : null;
    setUserLocalStorageData(readFromLocalStorage);
    getUserStoreData(readFromLocalStorage.auth.current_store);      //new one imp to get user outlet data
    if(readFromLocalStorage?.auth?.configuration) {
      setShowFetchOeOrdersCheck(readFromLocalStorage.auth.configuration?.oe_order_sync_enable);
    }
    /*--------------set user local data-------------------------------*/

    return () => {
      mounted = false;
    }

  }, []);  //imp to render when history prop changes




  const fetchSalesOrdersData = async (pageLimit = 100, pageNumber = 1, filter) => {

    document.getElementById('app-loader-container').style.display = "block";
    const omniSalesOrdersViewResponse = await EcommerceApiUtil.getAllOmniSales(
      pageLimit,
      pageNumber,
      filter,
    );
    console.log('omniSalesOrdersViewResponse:', omniSalesOrdersViewResponse);

    if (omniSalesOrdersViewResponse.hasError) {
      console.log('Cant fetch Omni Sales Orders Data -> ', omniSalesOrdersViewResponse.errorMessage);
      setLoading(false);
      /*------------------new verion---------------------*/
      setOmniSalesOrdersData([]);
      setSelectedTabData([]);                       //imp new ver
      handledSearchedDataResponse([]);
      setPaginationData({});
      /*------------------new verion---------------------*/
      document.getElementById('app-loader-container').style.display = "none";
      //message.warning(omniSalesOrdersViewResponse.errorMessage, 3);
    }
    else {
      console.log('res -> ', omniSalesOrdersViewResponse);
      if (mounted) {     //imp if unmounted
        var omniSalesData = omniSalesOrdersViewResponse.invoices.data || omniSalesOrdersViewResponse.invoices;
        setOmniSalesOrdersData(omniSalesData);
        setPaginationData(omniSalesOrdersViewResponse.invoices.page || {});
        /*-------setting sales data selection---------*/
        //handletabChangeData(omniSalesData);           //imp prev ver
        setSelectedTabData(omniSalesData);              //imp new ver
        /*-------setting continue sales data---------*/
        /*----------handle data serching response------------*/
        handledSearchedDataResponse(omniSalesData);
        /*-----------handle data serching response-----------*/
        setLoading(false);
        //document.getElementById('app-loader-container').style.display = "none";      //imp pre ver
        //message.success(omniSalesOrdersViewResponse.message, 3);
      }
    }
  }



  function handledSearchedDataResponse(dataResponse) {

    dataResponse.forEach(item => {
      var foundObj = dataSearched.find(obj => {
        return obj.invoice_id === item.invoice_id;
      });

      if (!foundObj) {
        dataSearched.push(item);
      }
    });

    document.getElementById('app-loader-container').style.display = "none";      //imp new ver
    
  }


  const handleSetOrdersStatus = (activeTab) => {
    let orderFilter;

    if (activeTab === omniSalesTabsEnum.ALL) {
      orderFilter = "";
    }
    if (activeTab === omniSalesTabsEnum.SALE) {
      orderFilter = "sale_order";
    }
    if (activeTab === omniSalesTabsEnum.COMPLETED) {
      orderFilter = "completed";
    }
    if (activeTab === omniSalesTabsEnum.RETURNED) {
      orderFilter = "return";
    }


    return {filter: orderFilter};

  };


  const handletabChange = (key) => {
    const {filter} = handleSetOrdersStatus(key);
    setCurrentTab(key);                    //imp better to set before fetch
    dataSearched = [];                     //imp new ver
    setCurrentPage(1);                     //imp new ver
    setSearchInputValue("");               //imp new ver
    fetchSalesOrdersData(paginationLimit, 1, filter);       //imp new ver

  };


  const onInputSearch = (e) => {
    setSearchInputValue(e.target.value);                                //imp new ver

  };



  const onSearch = (value) => {
    //let searchValue = e.target.value;                                 //imp prev ver
    let searchValue = value;
    //searchValue = searchValue.toLowerCase();
    //setSearchInputValue(searchValue);                                 //imp to set first
    const {filter} = handleSetOrdersStatus(currentTab);                 //imp new ver

    if (searchValue === "") {
      fetchSalesOrdersData(paginationLimit, currentPage, filter);       //imp new ver
      return;
    }

    setCurrentPageSearched(1);   //imp
    fetchSearchEcommerceInvoices(paginationLimit, 1, searchValue, filter);

  };



  const fetchSearchEcommerceInvoices = async (pageLimit = 20, pageNumber = 1, searchValue, tabFilter) => {
    document.getElementById('app-loader-container').style.display = "block";
    const EcommerceInvoicesSearchResponse = await EcommerceApiUtil.searchEcommerceInvoices(
      pageLimit,
      pageNumber,
      searchValue,
      tabFilter,
    );
    console.log('EcommerceInvoicesSearchResponse:', EcommerceInvoicesSearchResponse);
    if (EcommerceInvoicesSearchResponse.hasError) {
      console.log('Cant Search Customers -> ', EcommerceInvoicesSearchResponse.errorMessage);
      message.warning(EcommerceInvoicesSearchResponse.errorMessage, 2);
      setLoading(false);
      document.getElementById('app-loader-container').style.display = "none";
    }
    else {
      console.log('res -> ', EcommerceInvoicesSearchResponse.message);
      if (mounted) {     //imp if unmounted
        setOmniSalesOrdersData(EcommerceInvoicesSearchResponse.Ecommerce.data);
        setPaginationData(EcommerceInvoicesSearchResponse.Ecommerce.page);
        setSelectedTabData(EcommerceInvoicesSearchResponse.Ecommerce.data);              //imp new ver
        setLoading(false);
        document.getElementById('app-loader-container').style.display = "none";
      }
    }

  }



  function handleSaleOrderSelection(selectedRowKeys, selectedRows) {
    setSelectedSaleOrdersRowKeys(selectedRowKeys);

  }


  const confirmOeSalesOrders = async (selectedOeSalesOrders) => {

    document.getElementById('app-loader-container').style.display = "block";
    const confirmOeSalesOrdersResponse = await EcommerceApiUtil.confirmOeSalesOrders(selectedOeSalesOrders);
    console.log('confirmOeSalesOrdersResponse:', confirmOeSalesOrdersResponse);

    if (confirmOeSalesOrdersResponse.hasError) {
      console.log('Cant Confirm Oe Sales Orders Data -> ', confirmOeSalesOrdersResponse.errorMessage);
      document.getElementById('app-loader-container').style.display = "none";
      message.warning(confirmOeSalesOrdersResponse.errorMessage, 3);
    }
    else {
      if (mounted) {     //imp if unmounted
        //document.getElementById('app-loader-container').style.display = "none";   //imp but prev ver
        //message.success(confirmOeSalesOrdersResponse.message, 3);
        //fetchSalesOrdersData(paginationLimit, currentPage);                       //imp but prev ver

      }
    }
  }



  const fetchOeSaleOrderDataInBulk = async (invoice_id) => {

    const fetchOeSaleOrderViewResponse = await EcommerceApiUtil.getOeSaleOrderData(invoice_id);
    console.log('fetchOeSaleOrderViewResponse:', fetchOeSaleOrderViewResponse);

    if (fetchOeSaleOrderViewResponse.hasError) {
      console.log('Cant fetch Oe Sales Orders Data -> ', fetchOeSaleOrderViewResponse.errorMessage);
      document.getElementById('app-loader-container').style.display = "none";
      message.error(fetchOeSaleOrderViewResponse.errorMessage, 3);

    }
    else {
      if (mounted) {     //imp if unmounted
        let invoiceResponseData = {};
        invoiceResponseData.customer_info = fetchOeSaleOrderViewResponse.customer_info;
        invoiceResponseData.invoice_data = fetchOeSaleOrderViewResponse.invoice_data;
        invoiceResponseData.invoice_products = fetchOeSaleOrderViewResponse.invoice_products;
        return invoiceResponseData;

      }
    }

  }


  const BulkOeSalesOrdersRequest = () => {
    history.push({
      pathname: '/ecommerce/orders/bulk',
    });

  }


  const fetchOeSalesOrdersRequest = async () => { 

    let startDate = prompt(
      'Enter a start date (MM/DD/YYYY)',
      moment().subtract(7, 'days').calendar()
    );
    if (!startDate) return;

    document.getElementById('app-loader-container').style.display = "block";
    const fetchOeSalesOrdersResponse = await EcommerceApiUtil.fetchOeSalesOrders(startDate);
    console.log('fetchOeSalesOrdersResponse:', fetchOeSalesOrdersResponse);
    const {filter} = handleSetOrdersStatus("All Orders");     //imp new ver

    if (fetchOeSalesOrdersResponse.hasError) {
      console.log('Cant Confirm Oe Sales Orders Data -> ', fetchOeSalesOrdersResponse.errorMessage);
      document.getElementById('app-loader-container').style.display = "none";
      message.warning(fetchOeSalesOrdersResponse.errorMessage, 3);

    }
    else {
      console.log('res -> ', fetchOeSalesOrdersResponse);

      if (mounted) {     //imp if unmounted
        setLoading(false);
        document.getElementById('app-loader-container').style.display = "none";
        //message.success(fetchOeSalesOrdersResponse.message, 3);
        //fetchSalesOrdersData();   //imp to fetch again but prev ver
        /*-------------------new ver-----------------------*/
        setCurrentTab("All Orders");
        setCurrentPage(1);
        setPaginationLimit(100);
        setSelectEntriesValue(100);
        fetchSalesOrdersData(100, 1, filter);  //imp new ver
        /*---------------------new ver---------------------*/

      }
    }
  }



  const makeOeSaleOrderInvoice = async () => {
    if (selectedSaleOrdersRowKeys.length === 0) {
      message.warning("Please Select a Sale Order", 3);
    }
    else {
      await confirmOeSalesOrders(selectedSaleOrdersRowKeys);   //imp await is neccessary
      window.location.reload();                                //new one should be at the last
    }

  }



  const makeOeSaleOrderInvoiceInBulk = async () => {
    if (selectedSaleOrdersRowKeys.length === 0) {
      message.warning("Please Select a Sale Order", 3);
    }
    else {
      await confirmOeSalesOrders(selectedSaleOrdersRowKeys);    //imp to set it's status to complete //imp await is neccessary
      //console.log(selectedSaleOrdersRowKeys);
      let bulkSalesInvoiceDataArr = [];
      document.getElementById('app-loader-container').style.display = "block";
      for (let i = 0; i < selectedSaleOrdersRowKeys.length; i++) {
        bulkSalesInvoiceDataArr[i] = await fetchOeSaleOrderDataInBulk(selectedSaleOrdersRowKeys[i]);
      }
      document.getElementById('app-loader-container').style.display = "none";
      //console.log("final-bulk", bulkSalesInvoiceDataArr);
      setSelectedSalesInvoiceBulkDataArr(bulkSalesInvoiceDataArr);    //imp to set array here
      downloadInvoicesInHtml();                                       //imp
      window.location.reload();                                       //new one should be at the last

    }

  }



  function downloadInvoicesInHtml() {
    let doc = document.getElementById("printSalesInvoiceBulkView").innerHTML;
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(doc));
    element.setAttribute('download', "sales_invoices_bulk" + new Date().toUTCString() + ".html");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }




  const getUserStoreData = async (storeId) => {
    document.getElementById('app-loader-container').style.display = "block";
    const getOutletViewResponse = await SetupApiUtil.getOutlet(storeId);
    console.log('getOutletViewResponse:', getOutletViewResponse);

    if (getOutletViewResponse.hasError) {
      console.log('Cant fetch Store Data -> ', getOutletViewResponse.errorMessage);
      //message.warning(getOutletViewResponse.errorMessage, 3);
      document.getElementById('app-loader-container').style.display = "none";
    }
    else {
      console.log('res -> ', getOutletViewResponse);
      let selectedStore = getOutletViewResponse.outlet;
      //message.success(getOutletViewResponse.message, 3);
      setOutletData(selectedStore);

    }
  }



  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLoading(true);
    const {filter} = handleSetOrdersStatus(currentTab);                        //imp new ver
    fetchSalesOrdersData(paginationLimit, page, filter);                       //imp new ver
    
  };


  function handleSearchedDataPageChange(currentPg) {
    setCurrentPageSearched(currentPg);
    setLoading(true);
    const {filter} = handleSetOrdersStatus(currentTab);                        //imp new ver
    fetchSearchEcommerceInvoices(paginationLimit, currentPg, searchInputValue, filter);
  }


  function handleChange(value) {
    setPaginationLimit(value);
    setSelectEntriesValue(value);                                                //imp new ver
    setLoading(true);
    const { filter } = handleSetOrdersStatus(currentTab);                        //imp new ver

    if (searchInputValue) {                                                      //imp is search data exists
      if (currentPageSearched > Math.ceil(paginationData.totalElements / value)) {
        setCurrentPageSearched(1);                                                    //imp new ver
        fetchSearchEcommerceInvoices(value, 1, searchInputValue, filter);
      } else {
        fetchSearchEcommerceInvoices(value, currentPageSearched, searchInputValue, filter);
      }
    }
    else {
      if (currentPage > Math.ceil(paginationData.totalElements / value)) {
        setCurrentPage(1);                                                            //imp new ver
        fetchSalesOrdersData(value, 1, filter);
      } else {
        fetchSalesOrdersData(value, currentPage, filter);
      }
    }

  }


  const showTotalItemsBar = (total, range) => {
    return `showed ${selectedTabData.length} from ${range[0]}-${range[1]} of ${total} items`
  };



  const saleOrdrsMenu = (
    <Menu>
      {showFetchOeOrdersCheck &&
        <Menu.Item key="0"
          onClick={fetchOeSalesOrdersRequest}
        >
          Fetch Orders
        </Menu.Item>}
      <Menu.Item key="1"
        onClick={BulkOeSalesOrdersRequest}
      >
        Bulk Order Process
      </Menu.Item>
    </Menu>
  );



  return (
    <>

      {/* print sales overview */}
      {(selectedSalesInvoiceBulkDataArr && selectedSalesInvoiceBulkDataArr.length >= 2) && (
        <PrintSalesOrders
          SalesInvoiceDataArr={selectedSalesInvoiceBulkDataArr}
          selectedOutlet={outletData && outletData}

        />
      )}

      <div className="page ecom-orders">
        <div className="page__header">
          <h1>Ecommerce</h1>

          <div className="page__header__buttons">
            <Dropdown overlay={saleOrdrsMenu} trigger={["click"]}>
              <Button
                type="Default"
                icon={<DownOutlined />}
                onClick={(e) => e.preventDefault()}
              >
                More <ProfileOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>


        <div className="page__content omni-sales-orders">

          <div className="action-row entries-dropdown" >
            <div className="action-row__element">
              Show
              <Select
                defaultValue="100"
                style={{ width: 120, margin: "0 5px" }}
                onChange={handleChange}
                value={selectEntriesValue}
              >
                <Option value="100">100</Option>
                <Option value="250">250</Option>
                <Option value="500">500</Option>
              </Select>
              entries
            </div>
            <div className="action-row__element">
              <div className="search">
                {selectedSaleOrdersRowKeys.length <= 1 && <Button type="primary"
                  onClick={makeOeSaleOrderInvoice}
                >Make Invoice
                </Button>}
                {selectedSaleOrdersRowKeys.length >= 2 && <Button type="primary"
                  onClick={makeOeSaleOrderInvoiceInBulk}
                >Bulk Invoice
                </Button>}
                <Search
                  allowClear
                  size="middle"
                  onChange={onInputSearch}
                  style={{ width: 300 }}
                  value={searchInputValue}
                  enterButton='Search'
                  onSearch={onSearch}           //imp new ver
                />
              </div>
            </div>
          </div>

          {/*-here prev pagination--*/}


          <Tabs activeKey={currentTab} onChange={handletabChange}>
            <TabPane tab="All Orders" key="All Orders">
              <OmniSalesOrders
                data={selectedTabData}
                paginationLimit={paginationLimit}
                paginationDataObj={paginationData}
                tableLoading={loading}
                onSaleOrderSelection={handleSaleOrderSelection}
                tableDataType={omniSalesTabsEnum.ALL}
                //currencySymbol={userLocalStorageData && userLocalStorageData.currency.symbol}    //imp prev ver
                currencySymbol={outletData && outletData.currency_symbol}
              />
            </TabPane>
            <TabPane tab="Sale Orders" key="Sale Orders">
              <OmniSalesOrders
                data={selectedTabData}
                paginationLimit={paginationLimit}
                paginationDataObj={paginationData}
                tableLoading={loading}
                onSaleOrderSelection={handleSaleOrderSelection}
                tableDataType={omniSalesTabsEnum.SALE}
                //currencySymbol={userLocalStorageData && userLocalStorageData.currency.symbol}
                currencySymbol={outletData && outletData.currency_symbol}
              />
            </TabPane>
            <TabPane tab="Completed Orders" key="Completed Orders">
              <OmniSalesOrders
                data={selectedTabData}
                paginationLimit={paginationLimit}
                paginationDataObj={paginationData}
                tableLoading={loading}
                onSaleOrderSelection={handleSaleOrderSelection}
                tableDataType={omniSalesTabsEnum.COMPLETED}
                //currencySymbol={userLocalStorageData && userLocalStorageData.currency.symbol}
                currencySymbol={outletData && outletData.currency_symbol}
              />
            </TabPane>
            <TabPane tab="Returned Orders" key="Returned Orders">
              <OmniSalesOrders
                data={selectedTabData}
                paginationLimit={paginationLimit}
                paginationDataObj={paginationData}
                tableLoading={loading}
                onSaleOrderSelection={handleSaleOrderSelection}
                tableDataType={omniSalesTabsEnum.RETURNED}
                //currencySymbol={userLocalStorageData && userLocalStorageData.currency.symbol}
                currencySymbol={outletData && outletData.currency_symbol}
              />
            </TabPane>
          </Tabs>

          <div className="search omni-sales-pagination">
            <Pagination
              total={paginationData && paginationData.totalElements}
              showTotal={(total, range) => showTotalItemsBar(total, range)}
              defaultPageSize={100}
              //current={currentPage}
              current={ searchInputValue ? currentPageSearched : currentPage} 
              pageSize={parseInt(paginationLimit)}
              showSizeChanger={false}
              position="topRight"
              //onChange={(page, pageSize) => handlePageChange(page, pageSize)}
              onChange={(page, pageSize) => searchInputValue ?
                 handleSearchedDataPageChange(page, pageSize)  : handlePageChange(page, pageSize) }

              //onClickPageChanger={searchedData ? handleSearchedDataPageChange : handlePageChange}
              //currentPageIndex={searchedData ? currentPageSearched : currentPage}

            />
          </div>


        </div>
      </div>



    </>

  );
}

export default EcommerceOrders;
