
import React, { useState, useEffect } from "react";
import { Form, Upload, Button, message } from "antd";
import { useHistory } from "react-router-dom";
import { UploadOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import * as EcommerceApiUtil from '../../../../utils/api/ecommerce-api-utils';
import * as Helpers from "../../../../utils/helpers/scripts";



const InventorySyncFileUpload = () => {
    const history = useHistory();
    const [fileList, setFileList] = useState([]);

    let mounted = true;


    useEffect(() => {

        return () => {
            mounted = false;
        }

    }, []);  //imp to render when history prop changes



    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleUpload = async (values) => {
        //console.log("Success:", values);
        console.log(fileList[0]); //imp
        var file = fileList[0];

        if (file && fileExtention(file.name) == "csv") {
            var reader = new FileReader();
            reader.readAsText(file);
            reader.onload = function (evt) {
                //console.log(Helpers.CSV2JSONUpdated(evt.target.result));
                let jsonOutput = JSON.parse(Helpers.CSV2JSON(evt.target.result));  //1st custom function for conversion
                //console.log(jsonOutput);

                /*-------------------------------*/

                let products = jsonOutput.map((e) => ({
                    sku: e['Candela SKU'],
                    name: e['Product Name'],
                    quantity: e['Quantity'],
                    size: e['Size'],
                    colour: e['Colour'],
                    retail: e['Retail Price'],
                    sale: Helpers.var_check(e['Sale Price']) ? e['Sale Price'] : '0',
                }));
                //console.log(products);
                uploadInventoryFileCsv(products);


            };
            reader.onerror = function (evt) {
                message.error("error reading file");
            };
        } else {
            message.error("Not a csv file");
        }
    };



    const uploadInventoryFileCsv = async (inventoryProducts) => {
        console.log("inventoryProducts->", inventoryProducts);

        document.getElementById('app-loader-container').style.display = "block";
        const oeInventoryFileUploadResponse = await EcommerceApiUtil.uploadInventoryFileCsv(inventoryProducts);
        console.log('oeInventoryFileUploadResponse:', oeInventoryFileUploadResponse);

        if (oeInventoryFileUploadResponse.hasError) {
            console.log('Cant Upload OE Inventory Csv File Data -> ', oeInventoryFileUploadResponse.errorMessage);
            document.getElementById('app-loader-container').style.display = "none";
            message.warning(oeInventoryFileUploadResponse.errorMessage, 3);
        }
        else {
            if (mounted) {     //imp if unmounted
                document.getElementById('app-loader-container').style.display = "none";   //imp but prev ver
                setTimeout(() => {
                    history.push({
                        pathname: '/ecommerce/inventory-sync',
                    });
                }, 500);

            }
        }
    }




    function fileExtention(filename) {
        var parts = filename.split(".");
        return parts[parts.length - 1];
    }


    const imageUploadProps = {
        beforeUpload: (file) => {
            console.log(file);
            const isJpgOrPng =
                file.type === "image/jpeg" || file.type === "image/png";
            if (isJpgOrPng) {
                message.error("You cant upload JPG/PNG file!");
            } else {
                setFileList([file]);
            }

            return false;
        },
        fileList,
    };


    const onRemoveImage = (file) => {
        setFileList([]);
    };


    const handleCancel = () => {
        history.push({
            pathname: '/ecommerce/inventory-sync',
        });
    };




    return (
        <div className="page omni-bulk-sales-orders">
            <div className="page__header">
                <h1><Button type="primary" shape="circle" className="back-btn"
                    icon={<ArrowLeftOutlined />}
                    onClick={handleCancel} />Upload file</h1>
            </div>

            <div className="page__content">
                <div className="page__form">
                    <Form
                        name="basic"
                        layout="vertical"
                        //initialValues={{ }}
                        onFinish={handleUpload}
                        onFinishFailed={onFinishFailed}
                    >
                        <div className='form__row'>
                            <div className='form__col'>
                                <Form.Item  >
                                    <Upload {...imageUploadProps}
                                        listType="picture"
                                        onRemove={onRemoveImage}
                                    >
                                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                                    </Upload>
                                </Form.Item>

                            </div>

                            <div className="form__col">
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="u-width-100 custom-btn custom-btn--primary"
                                        //onClick={handleUpload}
                                    >
                                        Upload inventory file
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default InventorySyncFileUpload;
