
import React, { useEffect, useState } from "react";
import { Button, Tabs, Menu, Dropdown, DatePicker, Divider, message } from "antd";
import { ProfileOutlined, DownOutlined, BarsOutlined, DownloadOutlined } from "@ant-design/icons";
import PurchaseOrders from "./Po";
import InventoryTransfers from "./Transfer";
import StockAdjustment from "./Adjustment";
import StockReturned from "./ReturnedStock";
import Constants from '../../../utils/constants/constants';
import {
  getDataFromLocalStorage,
  saveDataIntoLocalStorage,
  clearKeyFromLocalStorage,
} from "../../../utils/local-storage/local-store-utils";
import { useHistory } from "react-router-dom";
import moment from 'moment';

const dateFormat = "YYYY-MM-DD";

const { TabPane } = Tabs;

let selectedDates = [];         //imp new ver



function Stock(props) {
  const history = useHistory();
  const historySelectedDatesValue  = history.location.selectedDateRange || [];
  const [userLocalStorageData, setUserLocalStorageData] = useState(null);
  const [selectedDatesRange, setselectedDatesRange] = useState([]);
  const [exportCsvCheck, setExportCsvCheck] = useState(false);

 
  //let selectedDates = [];                //imp prev ver
  const todayDate = moment();
  const { RangePicker } = DatePicker;

  const { activeKey = "" } = props;
  //const [currentTab, setCurrentTab] = useState("");
  //console.log("props", props);


  useEffect(() => {
    /*if(history.location.activeKey){
      setCurrentTab(history.location.activeKey);
    }
    else{
      //console.log(window.location.pathname);
      var path = (window.location.pathname).split("/");
      //console.log(path);
      setCurrentTab(path[2]); 
    } */

    /*--------------set user local data-------------------------------*/
    let readFromLocalStorage = getDataFromLocalStorage(Constants.USER_DETAILS_KEY);
    readFromLocalStorage = readFromLocalStorage.data ? readFromLocalStorage.data : null;
    setUserLocalStorageData(readFromLocalStorage);
    /*--------------set user local data-------------------------------*/


    return () => {
      console.log("stock-unmount");
      if (history.location) {
        let routePathName = history.location.pathname.split("/")[1];   //imp to split path
        if (routePathName !== "stock-control") {
          clearKeyFromLocalStorage(Constants.STOCK_DATE_RANGE);
        }
      }
    }



  }, [history.location.activeKey]);  //imp to render when history prop changes

  

  const handletabChange = (key) => {
    history.push({
      pathname: `/stock-control/${key}`,
      activeKey: key,
      selectedDateRange:  selectedDatesRange.length > 0 ? selectedDatesRange : historySelectedDatesValue,
    })
  };


  
  const stockScopeFilter = (localUserInfo) => {
    if (!localUserInfo) { return; }
    if (localUserInfo.user_role == 'cashier' || localUserInfo.user_role == 'shop_manager') {
      return false;
    }
    else {
      return true;
    }

  };



  const handleRangePicker = (values) => {
    if (values) {
      let startDate = moment(values[0]).format(dateFormat);
      let endDate = moment(values[1]).format(dateFormat);
      selectedDates[0]=startDate;
      selectedDates[1]=endDate;
    }
  };

  const fetchSalesHistoryWithDateRange = (e) => {
    let dates = [...selectedDates];
    setExportCsvCheck(false);
    setselectedDatesRange(dates);
    saveDataIntoLocalStorage(
      Constants.STOCK_DATE_RANGE,
      dates
    );
  };


  const ExportToCsv = () => {
    let dates = [...selectedDates];
    setExportCsvCheck(true);
    setselectedDatesRange(dates);   //imp to set here too for rerendering childs
  };



  const optionsDropdown = (
    <Menu>

      {userLocalStorageData && stockScopeFilter(userLocalStorageData.user_info || null) &&
        <Menu.Item
          key="0"
          onClick={() => history.push({ pathname: "/stock-control/purchase-orders/add" })}
        >
          <a>Order Stock</a>
        </Menu.Item>}

      <Menu.Divider />
      
      {userLocalStorageData && stockScopeFilter(userLocalStorageData.user_info || null) &&
        <Menu.Item
          key="1"
          onClick={() => history.push({ pathname: "/stock-control/return-stock/add" })}
        >
          Return Stock
      </Menu.Item>}

      <Menu.Divider />
      <Menu.Item
        key="2"
        onClick={() => history.push({ pathname: "/stock-control/inventory-transfers/add" })}
      >
        Transfer Inventory
      </Menu.Item>
      <Menu.Divider />

      {userLocalStorageData && stockScopeFilter(userLocalStorageData.user_info || null) &&
        <Menu.Item
          key="3"
          onClick={() => history.push({ pathname: "/stock-control/stock-adjustments/add" })}
        >
          Stock Adjustment
      </Menu.Item>}

    </Menu>
  );



  console.log( history.location);         //imp

  let readFromLocalStorage = getDataFromLocalStorage(
    Constants.STOCK_DATE_RANGE
  );
  readFromLocalStorage = readFromLocalStorage.data
    ? readFromLocalStorage.data
    : null;

  //console.log(readFromLocalStorage);         //imp
  let startDate =  historySelectedDatesValue[0] ? moment(historySelectedDatesValue[0]) : todayDate;
  let finishDate = historySelectedDatesValue[1] ? moment(historySelectedDatesValue[1]) : todayDate;
  //let componentPassedDatesRange =  selectedDatesRange.length > 0 ? selectedDatesRange : historySelectedDatesValue;      //imp prev ver
  let componentPassedDatesRange = readFromLocalStorage ? readFromLocalStorage : [];
  let defaultSelectedDateRange = readFromLocalStorage ? [moment(readFromLocalStorage[0]), moment(readFromLocalStorage[1])]
    : [startDate, finishDate];




  return (
    <div className="page setup">
      <div className="page__header">
        <h1>Stock Control</h1>

        <div className="page__header__buttons">

          <Button type='primary'
            className='custom-btn custom-btn--primary'
            icon={<DownloadOutlined />}
            onClick={ExportToCsv}
          >
            Export CSV
          </Button>

          <Dropdown overlay={optionsDropdown}
            placement="bottomCenter" trigger={["click"]}>
            <Button
              type="Default"
              icon={<DownOutlined />}
              onClick={(e) => e.preventDefault()}
            >
              More <ProfileOutlined />
            </Button>
          </Dropdown>
        </div>

      </div>


      <div className="page__content stock-control">

        <div className='action-row stock-transfer-row-date-picker'>
          <RangePicker
            className='date-picker'
            onCalendarChange={handleRangePicker}
            defaultValue={defaultSelectedDateRange}
            ranges={{
              Today: [moment(), moment()],
              'Yesterday': [moment().subtract(1, "days"), moment().subtract(1, "days")],
              'Last 7 days': [moment().subtract(7, "days"), moment()],
              'Last 30 Days': [moment().subtract(30, "days"), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Year': [moment().subtract(1, "year"), moment()],
            }}
          />
          <Button
            type='primary'
            icon={<BarsOutlined />}
            onClick={fetchSalesHistoryWithDateRange}
            className='custom-btn custom-btn--primary'
          >
            Fetch
            </Button>
        </div>
        <Divider />


        <Tabs activeKey={activeKey && activeKey} onChange={handletabChange}>

          {userLocalStorageData && stockScopeFilter(userLocalStorageData.user_info || null) &&
            <TabPane tab="Purchase Orders" key="purchase-orders">
              <PurchaseOrders selectedDates={componentPassedDatesRange} exportTransferCheck={exportCsvCheck}  />
            </TabPane>}

          <TabPane tab="Inventory Transfers" key="inventory-transfers">
            <InventoryTransfers selectedDates={componentPassedDatesRange} exportTransferCheck={exportCsvCheck} />
          </TabPane>

          {userLocalStorageData && stockScopeFilter(userLocalStorageData.user_info || null) &&
            <TabPane tab="Stock Adjustment" key="stock-adjustments">
              <StockAdjustment selectedDates={componentPassedDatesRange} exportTransferCheck={exportCsvCheck}  />
            </TabPane>}

          {userLocalStorageData && stockScopeFilter(userLocalStorageData.user_info || null) &&
            <TabPane tab="Returned Stock" key="returned-stock">
              <StockReturned selectedDates={componentPassedDatesRange} exportTransferCheck={exportCsvCheck}  />
            </TabPane>}

        </Tabs>
      </div>
    </div>
  );
}

export default Stock;
